@if (busy()) {
  <p-progressSpinner strokeWidth="4"></p-progressSpinner>
} @else {
  <button
    [disabled]="disabled()"
    pButton
    type="button"
    class="ui-button-bare-neutral"
    icon="pi pi-{{ icon() }}"
    (click)="onClick.emit()"
    title="{{ hoverText() }}"
  ></button>
}
