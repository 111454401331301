import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AssetSearchResultItem, ENVIRONMENT } from '@assethub/shared/models';
import { AssetSearchService } from '@assethub/shared/services/asset-search.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SkeletonModule } from 'primeng/skeleton';
import { Observable, catchError, finalize, forkJoin, map, switchMap, tap } from 'rxjs';
import { HighlightTextPipe } from '../../pipes/text-highlight-pipe';
import { LicensingService, TreeService } from '../../services';
import { DeepReadonly } from '../../utils';
import { AssetIconComponent } from '../asset-icon/asset-icon.component';
import { ExpandableSearchFieldComponent } from '../expandable-search-field/expandable-search-field.component';
import { IconComponent } from '../icon/icon.component';
import { MenuComponent } from '../menu/menu/menu.component';

interface WithCustomerId {
  customerNumber: string;
  subsidiaryCode: string;
}
interface WithId {
  uuid: string;
}
interface WithRelatedAssetId {
  assetId: string;
}

interface WorkOrderSearchContent extends WithId {
  name: string;
  number: number;
  created: number;
}
type WorkOrderHighlights = Pick<WorkOrderSearchContent, 'name' | 'number'>;
interface WithWorkOrderHighlights {
  highlights: WorkOrderHighlights;
}
type FoundWorkOrder = WorkOrderSearchContent & WithCustomerId & WithWorkOrderHighlights;
type WorkOrderSearchResponse = FoundWorkOrder[];
type FoundWorkOrderWithAssetId = FoundWorkOrder & WithRelatedAssetId;

interface Document {
  title: string;
  description?: string;
  type: string;
  uuid: string;
  lastModified: number;
  created: number;
  customerNumber: string;
  subsidiaryCode: string;
}

interface GetDocumentsResponse {
  documents: Document[];
  total: number;
}

type DocumentWithAssetId = Document & WithRelatedAssetId;

interface CaseSearchConent extends WithId {
  title: string;
  ticketNumber: string;
  created: number;
}
type CaseHighlights = Pick<CaseSearchConent, 'title' | 'ticketNumber'>;
interface WithCaseHighlights {
  highlights: CaseHighlights;
}

type FoundCase = CaseSearchConent & WithCustomerId & WithCaseHighlights;
type CaseSearchResponse = FoundCase[];
type FoundCaseWithAssetId = FoundCase & WithRelatedAssetId;

type Content = 'assets' | 'cases' | 'workOrders' | 'documents';
type SearchState = 'searching' | 'empty' | 'error';

interface ResultItem extends WithId, WithRelatedAssetId {
  content: Content;
  primaryLabel: string;
  secondaryLabel?: string;
  keyword: string;
  assetImage?: {
    type: string | number;
    productPictureUrl?: string;
    profilePictureUuid?: string;
  };
  icon?: string;
  state?: SearchState;
}

interface ResultGroup {
  label: string;
  items: ResultItem[];
}

interface Translations {
  groups: {
    [k: string]: {
      label: string;
      fields: { [k: string]: string };
    };
  };
}

@UntilDestroy()
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  standalone: true,
  imports: [
    MenuComponent,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    SkeletonModule,
    NgSwitchDefault,
    NgIf,
    AssetIconComponent,
    IconComponent,
    ExpandableSearchFieldComponent,
    TranslateModule,
    HighlightTextPipe,
  ],
})
export class GlobalSearchComponent {
  @ViewChild('overlay', { static: true }) private overlay: MenuComponent;

  groups: ResultGroup[] = [];
  selection?: ResultItem | string;
  expandState: boolean = false;

  private assets: ResultGroup;
  private cases: ResultGroup;
  private workOrders: ResultGroup;
  private documents: ResultGroup;
  private tr?: DeepReadonly<Translations>;
  private hasSM365 = false;

  private sm365Url = inject(ENVIRONMENT).sm365Url;
  constructor(
    private assetSearchService: AssetSearchService,
    private httpClient: HttpClient,
    private router: Router,
    private languageService: TranslateService,
    private treeService: TreeService,
    private licensingService: LicensingService,
  ) {
    this.clearResultGroups();
    this.languageService
      .get('global-search')
      .pipe(untilDestroyed(this))
      .subscribe(t => this.onTranslations(t));

    this.hasSM365 = this.licensingService.hasServiceModuleInSync;
  }

  private clearResultGroups() {
    this.assets = { label: '', items: [] };
    this.cases = { label: '', items: [] };
    this.workOrders = { label: '', items: [] };
    this.documents = { label: '', items: [] };
  }

  private onSearchChanged(event) {
    const searchText = event.trim();
    if (searchText.length < 3) {
      this.groups = [];
      this.overlay.hide();
      return;
    }

    this.updateStateForContentGroup('assets', 'searching');
    this.updateStateForContentGroup('cases', 'searching');
    this.updateStateForContentGroup('workOrders', 'searching');
    this.updateStateForContentGroup('documents', 'searching');

    // according to documentation "suggestions" is null by default and one has to set it to null to
    // make p-autocomplete show its loading spinner but the type-definitions seem to disagree
    this.groups = null as unknown as [];

    if (this.hasSM365) {
      this.searchWorkOrders(searchText);
      this.searchCases(searchText);
      this.searchDocuments(searchText);
    }

    this.searchAssets(searchText);

    if (!this.expandState) {
      this.overlay.hide();
    } else {
      this.overlay.show();
    }
  }

  searchChanged(event: string) {
    this.onSearchChanged(event);
  }

  expandChanged(event: boolean) {
    this.expandState = event;
  }

  onSelection(item: ResultItem | string) {
    if (typeof item === 'string') {
      return;
    }
    this.selection = undefined;
    if (item.state) {
      return;
    }
    switch (item.content) {
      case 'assets':
        this.router.navigate(['/asset', item.assetId, 'asset-management']);
        break;
      case 'cases':
        this.router.navigate(['/maintenance365', item.assetId, 'request', item.uuid, 'details']);
        break;
      case 'workOrders':
        this.router.navigate(['/maintenance365', item.assetId, 'workorder', item.uuid, 'details']);
        break;
      case 'documents':
        this.router.navigate(['/maintenance365', item.assetId, 'documents']);
        break;
    }
    this.overlay.hide();
  }

  private searchWorkOrders(searchText: string) {
    this.httpClient
      .post<WorkOrderSearchResponse>(`${this.sm365Url}/workorders/search`, {
        search: searchText,
      })
      .pipe(
        tap(workOrders => {
          if (workOrders.length === 0) {
            this.updateStateForContentGroup('workOrders', 'empty');
          }
        }),
        switchMap(workOrders => forkJoin(workOrders.map(x => this.appendAssetIdForCustomerId(x)))),
        map(workOrders => workOrders.map(x => this.convertWorkOrder(x, searchText))),
        tap(resultItems => {
          this.workOrders.items = resultItems;
        }),
        catchError(err => {
          this.updateStateForContentGroup('workOrders', 'error');
          throw err;
        }),
        finalize(() => this.updateGroups()),
      )
      .subscribe();
  }

  private searchDocuments(searchText: string) {
    this.httpClient
      .get<GetDocumentsResponse>(`${this.sm365Url}/documents/search`, {
        params: {
          offset: '0',
          size: '3',
          order: 'lastModified',
          dir: 'desc',
          title: searchText,
        },
      })
      .pipe(
        tap(result => {
          if (result.documents.length === 0) {
            this.updateStateForContentGroup('documents', 'empty');
          }
        }),
        switchMap(response =>
          forkJoin(response.documents.map(x => this.appendAssetIdForCustomerId(x))),
        ),
        map(documents => documents.map(x => this.convertDocument(x, searchText))),
        tap(resultItems => {
          this.documents.items = resultItems;
        }),
        catchError(err => {
          this.updateStateForContentGroup('documents', 'error');
          throw err;
        }),
        finalize(() => this.updateGroups()),
      )
      .subscribe();
  }

  private searchCases(searchText: string) {
    this.httpClient
      .post<CaseSearchResponse>(`${this.sm365Url}/cases/search`, { search: searchText })
      .pipe(
        tap(cases => {
          if (cases.length === 0) {
            this.updateStateForContentGroup('cases', 'empty');
          }
        }),
        switchMap(cases => forkJoin(cases.map(x => this.appendAssetIdForCustomerId(x)))),
        map(cases => cases.map(x => this.convertCase(x, searchText))),
        tap(resultItems => {
          this.cases.items = resultItems;
        }),
        catchError(err => {
          this.updateStateForContentGroup('cases', 'error');
          throw err;
        }),
        finalize(() => this.updateGroups()),
      )
      .subscribe();
  }

  private searchAssets(searchText: string) {
    this.assetSearchService
      .searchAssets(
        { searchValue: searchText },
        { first: 0, rows: 3, sortField: 'updated', sortOrder: -1 },
      )
      .pipe(
        map(response => response.items.map(x => this.convertAsset(x, searchText))),
        tap(resultItems => {
          if (resultItems.length === 0) {
            this.updateStateForContentGroup('assets', 'empty');
          } else {
            this.assets.items = resultItems;
          }
        }),
        catchError(err => {
          this.updateStateForContentGroup('assets', 'error');
          throw err;
        }),
        finalize(() => this.updateGroups()),
      )
      .subscribe();
  }

  private appendAssetIdForCustomerId<T extends WithCustomerId>(
    item: T,
  ): Observable<T & WithRelatedAssetId> {
    const cleanedSubsidiaryCode =
      item.subsidiaryCode.match(/^[A-Z]+/i)?.[0].toUpperCase() ?? item.subsidiaryCode;

    return this.treeService.findRootByCustomerId(item.customerNumber, cleanedSubsidiaryCode).pipe(
      map(asset => ({
        ...item,
        assetId: asset?.uuid ?? '',
      })),
    );
  }

  private updateGroups() {
    const groups = [this.assets];
    if (this.hasSM365) {
      groups.push(this.workOrders);
      groups.push(this.cases);
      groups.push(this.documents);
    }
    this.groups = groups.sort((a, b) => a.label.localeCompare(b.label));
  }

  private onTranslations(tr: Translations) {
    this.tr = tr;
    if (!this.tr) {
      return;
    }
    this.assets.label = this.tr.groups.assets.label;
    this.cases.label = this.tr.groups.cases.label;
    this.workOrders.label = this.tr.groups.workOrders.label;
    this.documents.label = this.tr.groups.documents.label;
    this.updateGroups();
  }

  private updateStateForContentGroup(content: Content, state: SearchState) {
    let resultGroup: ResultGroup;
    switch (content) {
      case 'assets':
        resultGroup = this.assets;
        break;
      case 'workOrders':
        resultGroup = this.workOrders;
        break;
      case 'cases':
        resultGroup = this.cases;
        break;
      case 'documents':
        resultGroup = this.documents;
        break;
      default:
        throw new Error();
    }
    resultGroup.items = [
      {
        content,
        uuid: '',
        primaryLabel: '',
        keyword: '',
        state: state,
        assetId: '',
      },
    ];
  }

  private convertAsset(item: AssetSearchResultItem, keyword: string): ResultItem {
    const keys = new Set<keyof AssetSearchResultItem>([
      'customName',
      'productName',
      'partNumber',
      'serialNumber',
    ]);
    let primaryLabel = '';
    if (item.customName) {
      primaryLabel = item.customName;
      keys.delete('customName');
    } else if (item.productName) {
      primaryLabel = item.productName;
      keys.delete('productName');
    } else if (item.partNumber) {
      primaryLabel = item.partNumber;
      if (item.serialNumber) {
        primaryLabel += ' / ' + item.serialNumber;
      }
      keys.delete('partNumber');
      keys.delete('serialNumber');
    } else {
      primaryLabel = item.uuid;
    }
    const secondary: string[] = [];
    for (const key of keys.keys()) {
      const value = item[key];
      if (value) {
        secondary.push(`${this.tr?.groups.assets.fields[key] || '?'}: ${item[key]}`);
      }
    }
    return {
      uuid: item.uuid,
      assetId: item.uuid,
      content: 'assets',
      primaryLabel,
      secondaryLabel: secondary.join(' | '),
      keyword,
      assetImage: {
        type: item.type,
        productPictureUrl: item.productPictureUrl,
        profilePictureUuid: item.profilePictureUuid,
      },
    };
  }

  private convertWorkOrder(item: FoundWorkOrderWithAssetId, keyword: string): ResultItem {
    const retval: ResultItem = {
      uuid: item.uuid,
      content: 'workOrders',
      primaryLabel: item.name,
      keyword,
      icon: 'checklist',
      assetId: item.assetId,
    };
    if (item.number) {
      retval.secondaryLabel = `${this.tr?.groups.workOrders.fields['number'] || '?'}: ${
        item.number
      }`;
    }
    return retval;
  }

  private convertDocument(item: DocumentWithAssetId, keyword: string): ResultItem {
    const retval: ResultItem = {
      uuid: item.uuid,
      content: 'documents',
      primaryLabel: item.title,
      keyword,
      icon: item.type === 'pdf' ? 'pi-file-pdf' : 'pi-file',
      assetId: item.assetId,
    };
    if (item.description) {
      retval.secondaryLabel = `${this.tr?.groups.workOrders.fields['description'] || '?'}: ${
        item.description
      }`;
    }
    return retval;
  }

  private convertCase(item: FoundCaseWithAssetId, keyword: string): ResultItem {
    const retval: ResultItem = {
      uuid: item.uuid,
      content: 'cases',
      primaryLabel: item.title,
      keyword,
      icon: 'work',
      assetId: item.assetId,
    };
    if (item.ticketNumber) {
      retval.secondaryLabel = `${this.tr?.groups.cases.fields['ticket-number'] || '?'}: ${
        item.ticketNumber
      }`;
    }
    return retval;
  }
}
