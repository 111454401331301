import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  effect,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  AssetImages,
  AssetTypeMapping,
  AssetUpdateRequest,
  ENVIRONMENT,
  FirmwareState,
  GetAssetResponse,
  ProductDetailsResultV2,
  RelationItem,
  assetTypeMappingToString,
  assetTypesSupportDeploymentDate,
  interchangeableTypeMappings,
} from '@assethub/shared/models';
import { CompleteUrlPipe } from '@assethub/shared/pipes/complete-url.pipe';
import {
  AssetStateService,
  AssetService,
  ConfirmationService,
  DeviceAlreadyExistsError,
  ImageService,
  ProductService,
  ProfilePictureService,
} from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { validateIsVendorSick } from '@assethub/shared/utils/validators/vendor-validation';
import { isVersionValid } from '@assethub/shared/utils/validators/version-validation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService, SelectItem, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Subscription, finalize, of, switchMap, tap } from 'rxjs';
import { CompleteUrlPipe as CompleteUrlPipe_1 } from '../../pipes/complete-url.pipe';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { ConnectionStateComponent } from '../connection-state/connection-state.component';
import { AssetOptionMenuComponent } from '../asset-option-menu/asset-option-menu.component';
import { CalloutComponent } from '../callout/callout.component';
import { DateFieldComponent } from '../date-field/date-field.component';
import { FirmwareStateIconComponent } from '../firmware-state-icon/firmware-state-icon.component';
import { AcceptCancelOptionsComponent } from '../save-cancel-options/save-cancel-options.component';
import { TextFieldComponent } from '../text-field/text-field.component';
import { SystemStateComponent } from '../system-state/system-state.component';
import { AdministratorsModalComponent } from '../modals/administrators-modal/administrators-modal.component';

const PRODUCT_FAMILY_PREFIX = 'g';

@Injectable({ providedIn: 'root' })
export class AssetDetailHeaderService {
  readonly statusBar = signal<TemplateRef<any> | undefined>(undefined);
  readonly optionMenu = signal<TemplateRef<any> | undefined>(undefined);
}

@UntilDestroy()
@Component({
  selector: 'app-asset-detail-header',
  templateUrl: './asset-detail-header.component.html',
  styleUrls: ['./asset-detail-header.component.scss'],
  standalone: true,
  imports: [
    DialogModule,
    ProgressSpinnerModule,
    NgStyle,
    ButtonModule,
    TextFieldComponent,
    FormsModule,
    NgTemplateOutlet,
    AssetOptionMenuComponent,
    NgClass,
    DropdownModule,
    SharedModule,
    FirmwareStateIconComponent,
    DateFieldComponent,
    CalloutComponent,
    AcceptCancelOptionsComponent,
    TranslateModule,
    CompleteUrlPipe_1,
    LocalizedDatePipe,
    SafeHtmlPipe,
    ConnectionStateComponent,
    SystemStateComponent,
  ],
})
export class AssetDetailHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  asset: GetAssetResponse;

  @Output()
  assetChange: EventEmitter<GetAssetResponse> = new EventEmitter();

  readonly statusBar = this.assetDetailHeaderService.statusBar;
  readonly optionMenu = this.assetDetailHeaderService.optionMenu;
  readonly editAsset = this.assetService.editMode;

  profilePicture: AssetImages = { ready: false };
  defaultPictureUrl = '';

  title = '';
  defaultTitle = '';
  vendor = '';
  serialNumber = '';
  partNumber = '';
  productName = '';
  shipImo = '';
  typeSelected: AssetTypeMapping | undefined;
  currentTypeAsString = '';
  firmwareVersion = '';
  firmwareState: FirmwareState = 'UNKNOWN';
  readonly firmwareStateOutdated: FirmwareState = 'UPDATE_AVAILABLE';
  deploymentDateTimestamp?: number;
  deploymentDate?: Date;
  shopPath = '';
  permissions?: string;

  deploymentDateLanguageKey = 'default';

  assetTypes: SelectItem[] = this.createAssetTypeList();
  assetTypeChangeable = false;
  showDeploymentDateField = false;

  phaseOutWarningMsg = '';
  successor: RelationItem | undefined;

  updating = false;

  readonly types = AssetTypeMapping;

  firmwareVersionValid = true;
  showVersionErrorIcon = false;
  displayInvalidFirmwareDialog = false;

  vendorUpdatable = false;
  serialNumberUpdatable = false;
  partNumberUpdatable = false;
  productNameUpdatable = false;
  envFeatures = inject(ENVIRONMENT).envSpecificFeatures;

  private profilePictureSubscription?: Subscription = undefined;
  private productDetailSubscription?: Subscription = undefined;

  private logger = new Logger(this.constructor.name);

  constructor(
    private assetService: AssetService,
    private profilePictureService: ProfilePictureService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private langService: TranslateService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private imageService: ImageService,
    private productService: ProductService,
    private completeUrlPipe: CompleteUrlPipe,
    private assetStateService: AssetStateService,
    private assetDetailHeaderService: AssetDetailHeaderService,
  ) {
    effect(() => {
      if (!this.editAsset()) {
        this.reset();
      }
    });
  }

  showVersionHelp = () => {
    this.displayInvalidFirmwareDialog = true;
  };

  ngOnInit(): void {
    if (!this.asset) {
      throw new Error('Asset property not set');
    }

    this.langService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateAssetAttributes(this.asset);
    });

    this.profilePictureService.profilePictureChanged.pipe(untilDestroyed(this)).subscribe(event => {
      if (!event || event.assetUuid !== this.asset.uuid) {
        return;
      }
      this.profilePicture = event.picture;
      const pic = this.profilePicture;
      if (!pic.zoomed && pic.uuid) {
        this.imageService.proxyImageObservable(pic.uuid).subscribe(url => {
          pic.zoomed = url;
        });
      }
    });
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.asset?.currentValue) {
      this.updateAsset(change.asset.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.profilePictureSubscription) {
      this.profilePictureSubscription.unsubscribe();
      this.profilePictureSubscription = undefined;
    }
    if (this.productDetailSubscription) {
      this.productDetailSubscription.unsubscribe();
      this.productDetailSubscription = undefined;
    }
  }

  navigateToMap(): void {
    this.router.navigate(['/asset', this.asset.uuid, 'map']);
  }

  openPicture(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    window.open(
      this.completeUrlPipe.transform(this.profilePicture.zoomed || this.profilePicture.normal!),
      '_blank',
    );
  }

  onAssetChanged(asset: GetAssetResponse): void {
    this.updateAsset(asset);
  }

  cancelEdit(): void {
    this.editAsset.set(false);
  }

  saveChangesToAsset(): void {
    const assetUpdate: AssetUpdateRequest = {};

    const newTitle = this.title.trim();
    if (newTitle !== this.asset.customName) {
      assetUpdate.customName = newTitle;
    }

    if (this.typeSelected !== this.asset.typeId) {
      assetUpdate.typeId = this.typeSelected;
    }

    this.deploymentDateTimestamp = this.deploymentDate
      ? Math.floor(this.deploymentDate.getTime() / 1000)
      : undefined;

    if (this.deploymentDateTimestamp !== this.asset.deploymentDate) {
      assetUpdate.deploymentDate = this.deploymentDateTimestamp
        ? this.deploymentDateTimestamp
        : null;
    }

    if (!this.assetTypeChangeable) {
      if (!this.typeSelected) {
        throw Error('Type property not set.');
      }

      if (this.isProduct() && this.asset.details) {
        if (this.firmwareVersion.trim() !== this.asset.details.firmwareVersion) {
          if (!this.firmwareVersionValid) {
            this.messageService.add({
              severity: 'error',
              summary: 'toasts.asset.invalid-firmware-error',
              life: 5000,
            });
            return;
          }
          assetUpdate.firmwareVersion = this.firmwareVersion.trim();
        }

        const newPartNumber = this.partNumber.trim();
        if (newPartNumber !== this.asset.details.partNumber) {
          assetUpdate.partNumber = newPartNumber;
        }

        const newSerialNumber = this.serialNumber.trim();
        if (newSerialNumber !== this.asset.details.serialNumber) {
          assetUpdate.serialNumber = newSerialNumber;
        }

        const newProductName = this.productName.trim();
        if (newProductName !== this.asset.details.productName) {
          assetUpdate.productName = newProductName;
        }

        const newVendor = this.vendor.trim();
        if (newVendor !== this.asset.details.vendor) {
          assetUpdate.vendor = newVendor;
        }
      } else if (this.isTypeShip() && typeof this.asset.shipDetails !== 'undefined') {
        const newImo = this.shipImo.trim();
        if (newImo !== this.asset.shipDetails.imo) {
          assetUpdate.imo = newImo;
        }
      }
    }
    if (Object.keys(assetUpdate).length === 0) {
      this.reset();
      return;
    }

    this.updating = true;
    this.assetService.updateAsset(assetUpdate, this.asset.uuid).subscribe({
      next: result => {
        Object.assign(this.asset, result);
        this.updating = false;
        this.editAsset.set(false);
        // this.leaveEditMode();
        // this.updateAsset(result);
        this.assetChange.emit(result);
      },
      error: error => {
        if (error instanceof DeviceAlreadyExistsError) {
          this.confirmationService.confirm({
            translationKey: 'assetDetails.duplicateError',
            accept: () => this.router.navigate(['asset', error.conflictingDeviceUuid]),
          });
        } else {
          this.messageService.add({
            severity: 'error',
            sticky: true,
            summary: 'toasts.asset.update-error',
          });
          this.logger.error(error);
        }
        this.updating = false;
        // Revert state
        this.reset();
      },
    });
  }

  canShowShopLink(): boolean {
    if (!this.asset.details?.partNumber) {
      return false;
    }

    return this.asset.details.partNumber.length > 4 && this.isVendorSICK();
  }

  isProduct(): boolean {
    return (
      this.asset.typeId === AssetTypeMapping.DEVICE ||
      this.asset.typeId === AssetTypeMapping.MACHINE_SYSTEM ||
      this.asset.typeId === AssetTypeMapping.SOFTWARE
    );
  }

  hasSerialNumber(): boolean {
    return (
      this.asset.typeId === AssetTypeMapping.DEVICE ||
      this.asset.typeId === AssetTypeMapping.MACHINE_SYSTEM
    );
  }

  hasVersionFormatValidation(): boolean {
    return (
      this.asset.typeId === AssetTypeMapping.DEVICE ||
      this.asset.typeId === AssetTypeMapping.MACHINE_SYSTEM
    );
  }

  isTypeShip(): boolean {
    return this.typeSelected === AssetTypeMapping.SHIP;
  }

  hasInventorySync() {
    return this.assetStateService.hasInventorySync(this.asset);
  }

  private reset(): void {
    this.typeSelected = undefined;
    this.resetFirmwareErrors();
    this.updateAsset(this.asset);
  }

  validateVersion(): void {
    const firmwareVersion = this.firmwareVersion.trim();
    this.firmwareVersionValid =
      firmwareVersion === '' ||
      !this.hasVersionFormatValidation() ||
      isVersionValid(firmwareVersion);
    if (this.showVersionErrorIcon && this.firmwareVersionValid) {
      this.showVersionErrorIcon = false;
    }
  }

  onVersionLeaveFocus(): void {
    this.showVersionErrorIcon = !this.firmwareVersionValid;
  }

  typeChanged(event: { originalEvent: Event; value: number }): void {
    if (event.value) {
      this.defaultTitle = this.langService.instant(
        `assetTypes.${assetTypeMappingToString[event.value]}`,
      );
    }
  }

  fallbackToIcon(): void {
    this.profilePicture = { ready: true };
  }

  isVendorSICK(): boolean {
    return !!this.asset.details && validateIsVendorSick(this.asset.details.vendor);
  }

  showAdmins(event: Event): void {
    event.preventDefault();
    AdministratorsModalComponent.open(this.asset.uuid, 'permission-insights.own-permission.r');
  }

  private updateAsset(asset: GetAssetResponse) {
    this.updateAssetAttributes(asset);
    this.updateProfilePicture(asset);
    this.updateProductDetails(asset);
  }

  private updateAssetAttributes(asset: GetAssetResponse) {
    this.asset = asset;
    this.defaultTitle = this.assetService.getTranslatedName(asset).defaultAssetName;
    this.title = asset.customName;

    this.deploymentDateTimestamp = asset.deploymentDate ? asset.deploymentDate : undefined;

    this.deploymentDate = asset.deploymentDate ? new Date(asset.deploymentDate * 1000) : undefined;

    this.currentTypeAsString = assetTypeMappingToString[asset.typeId];
    this.typeSelected = asset.typeId as AssetTypeMapping;
    this.assetTypeChangeable = interchangeableTypeMappings.includes(this.typeSelected);
    this.permissions = asset.permissions;

    if (assetTypesSupportDeploymentDate.includes(this.typeSelected)) {
      this.showDeploymentDateField = true;
      this.deploymentDateLanguageKey = assetTypeMappingToString[this.typeSelected] ?? 'default';
    } else {
      this.showDeploymentDateField = false;
    }

    if (this.isProduct()) {
      this.vendor = asset.details?.vendor || '';
      this.serialNumber = asset.details?.serialNumber || '';
      this.partNumber = asset.details?.partNumber || '';
      this.productName = asset.details?.productName || '';
      this.firmwareVersion = asset.details?.firmwareVersion || '';
      this.firmwareState = asset.details?.firmwareState || 'UNKNOWN';
      this.resetFirmwareErrors();
      this.vendorUpdatable = ['SICK', 'SICK AG'].includes(this.vendor) === false;
      this.serialNumberUpdatable = this.serialNumber === '';
      this.partNumberUpdatable = this.partNumber === '';
      this.productNameUpdatable = this.productName === '';
    } else if (this.isTypeShip()) {
      this.shipImo = asset.shipDetails?.imo || '';
    }
  }

  private resetFirmwareErrors() {
    this.firmwareVersionValid = true;
    this.showVersionErrorIcon = false;
  }

  private updateProfilePicture(asset: GetAssetResponse) {
    this.updating = true;
    this.defaultPictureUrl = this.imageService.getDefaultPictureUrl(asset);

    if (this.profilePictureSubscription) {
      this.profilePictureSubscription.unsubscribe();
      this.profilePictureSubscription = undefined;
    }

    this.profilePictureSubscription = this.profilePictureService
      .getProfilePicture(asset)
      .pipe(
        tap(event => {
          this.logger.info('Received profile picture', event.picture);
          this.profilePicture = event.picture;
        }),
        switchMap(() => {
          const uuid = this.asset.profilePicture?.uuid;
          if (!this.profilePicture.zoomed && uuid) {
            return this.imageService.proxyImageObservable(uuid).pipe(
              tap(url => {
                this.profilePicture.zoomed = url;
              }),
            );
          }

          return of(undefined);
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  private updateProductDetails(asset: GetAssetResponse) {
    if (this.productDetailSubscription) {
      this.productDetailSubscription.unsubscribe();
      this.productDetailSubscription = undefined;
    }

    this.resetView();

    if (asset.details && this.isVendorSICK() && asset.typeId !== AssetTypeMapping.SOFTWARE) {
      const partNumber = asset.details.partNumber;
      this.productDetailSubscription = this.productService.getProductDetails(partNumber).subscribe({
        next: productDetails => {
          this.processProductData(productDetails);
        },
        error: () => {
          this.shopPath = partNumber;
        },
      });
    }
  }

  private resetView() {
    this.phaseOutWarningMsg = '';
    this.successor = undefined;
  }

  private processProductData(productDetails: ProductDetailsResultV2) {
    const logistics = productDetails.Product.ERPData.Logistics;
    if (logistics) {
      if (logistics.ProductPhase === 'InPhaseOut') {
        this.phaseOutWarningMsg = 'assetDetails.phasingOutWarning';
      } else if (logistics.ProductPhase === 'PhasedOut') {
        this.phaseOutWarningMsg = 'assetDetails.phasedOutWarning';
      }
    }
    const relations = productDetails.Product.Relations.Relation;
    this.successor = relations ? relations.find(x => x.Type === 'SUCCESSOR') : undefined;

    this.shopPath = productDetails.Product.MetaData.ID.startsWith(PRODUCT_FAMILY_PREFIX)
      ? productDetails.Product.MetaData.Name.replace(/ /g, '_')
      : productDetails.Product.MetaData.PartNumber;
  }

  private createAssetTypeList(): SelectItem<number>[] {
    const supportedAssetTypes: SelectItem<number>[] = [];

    interchangeableTypeMappings.forEach(type => {
      supportedAssetTypes.push({
        label: 'assetTypes.' + assetTypeMappingToString[type],
        value: type,
        icon: 'assets/images/' + assetTypeMappingToString[type] + '.svg',
      });
    });

    return supportedAssetTypes;
  }

  sanitize(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
