import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  ENVIRONMENT,
  AssetInventoryDetails,
  OperatingData,
  OperatingDataResponse,
  AssetInventoryDetailsResponse,
} from '../models';
import {
  AssetMetadataV2,
  AssetUpdateRequest,
  GetAssetResponse,
  GetAssetTreesResponse,
  TranslatedAssetNames,
  assetTypeMappingToString,
} from '../models/asset-details';
import { MoveAssetPreflightInfo } from '../models/move-asset-preflight-checks';
import { UserConfiguration } from '../models/user-configuration';
import { Logger, fromUnixTimestamp } from '../utils';
import { ProfilePictureService } from './profile-picture.service';
import { TreeService } from './tree.service';
import { UserConfigurationService } from './user-configuration.service';

export class DeviceAlreadyExistsError {
  name = 'DeviceAlreadyExistsError';

  constructor(public conflictingDeviceUuid: string) {}
}

@Injectable({ providedIn: 'root' })
export class AssetService {
  public config: UserConfiguration;
  readonly editMode = signal(false);
  private asset: GetAssetResponse;

  private loadingProfilePicture?: Subscription;
  private logger = new Logger(this.constructor.name);
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private userConfigService: UserConfigurationService,
    private profilePictureService: ProfilePictureService,
    private treeService: TreeService,
    private router: Router,
  ) {
    this.userConfigService.config().subscribe(config => (this.config = config));
    this.router.events.subscribe({
      next: event => {
        if (event instanceof NavigationStart) {
          this.editMode.set(false);
        }
      },
    });
  }

  public getAsset(assetUuid: string) {
    return this.httpClient.get<GetAssetResponse>(this.apiUrl + '/asset/' + assetUuid).pipe(
      tap(asset => {
        this.asset = asset;
        this.postProcessAsset();
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 403 || error.status === 404) {
            this.router.navigate(['error', 'assetnotfound'], { skipLocationChange: true });
          }
        }
        throw error;
      }),
    );
  }

  public updateAsset(request: AssetUpdateRequest, assetUuid: string): Observable<GetAssetResponse> {
    return this.httpClient.put<GetAssetResponse>(this.apiUrl + '/asset/' + assetUuid, request).pipe(
      tap(response => {
        this.treeService.updateNode({
          uuid: assetUuid,
          name: response.customName,
          type: response.typeId,
          productName: response.details?.productName,
          productPictureUrl: response.details?.productPictureUrl,
        });
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 409 && 'conflictingDeviceUuid' in error.error) {
            throw new DeviceAlreadyExistsError(error.error.conflictingDeviceUuid);
          }
        }
        throw error;
      }),
    );
  }

  public moveAsset(
    assetId: string,
    destinationId: string,
    toStartOfChildren: boolean,
  ): Observable<void> {
    return this.httpClient
      .put<void>(`${this.apiUrl}/asset/${assetId}/move-to/${destinationId}`, {
        toStartOfChildren,
      })
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 400 && 'message' in err.error) {
              throw new Error(err.error.message);
            }
          }

          throw err;
        }),
      );
  }

  public moveAssetToDifferentTree(
    assetId: string,
    destinationId: string,
    keepHistory: boolean,
    // Please use this flag with caution. Only Admins and Owners of a destination tree are allowed to
    // use it.
    keepPermissions?: boolean,
  ): Observable<void> {
    const clearSrcTreePermissions = keepPermissions === undefined ? undefined : !keepPermissions;
    const clearSrcTreeHistory = !keepHistory;

    return this.httpClient.put<void>(`${this.apiUrl}/asset/${assetId}/move-to/${destinationId}`, {
      toStartOfChildren: true,
      clearSrcTreePermissions,
      clearSrcTreeHistory,
    });
  }

  public moveAssetPreflightChecks(
    assetId: string,
    destinationId: string,
    toStartOfChildren: boolean,
  ): Observable<MoveAssetPreflightInfo> {
    return this.httpClient.get<MoveAssetPreflightInfo>(
      `${this.apiUrl}/asset/${assetId}/move-to/${destinationId}`,
      {
        params: {
          toStartOfChildren,
        },
      },
    );
  }

  public getAssetTree(): Observable<GetAssetTreesResponse> {
    return this.httpClient.get<GetAssetTreesResponse>(this.apiUrl + '/trees');
  }

  public addAsset(newAsset: AssetMetadataV2): Observable<GetAssetResponse> {
    Object.assign(newAsset, { sourceType: 'web' });
    return this.httpClient.post<GetAssetResponse>(this.apiUrl + '/asset', newAsset).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 409 && 'conflictingDeviceUuid' in error.error) {
            throw new DeviceAlreadyExistsError(error.error.conflictingDeviceUuid);
          }
        }
        throw error;
      }),
    );
  }

  public deleteAsset(assetUuid: string) {
    return this.httpClient.delete<void>(this.apiUrl + '/asset/' + assetUuid);
  }

  public getTranslatedName(asset: GetAssetResponse = this.asset): TranslatedAssetNames {
    const assetType = assetTypeMappingToString[asset.typeId];
    const translatedAssetType = this.translateService.instant(`assetTypes.${assetType}`);
    const defaultAssetName = asset.details?.productName || translatedAssetType;
    return { assetTypeName: translatedAssetType, defaultAssetName };
  }

  public fetchInventoryOperatingData(assetId: string): Observable<OperatingData[]> {
    return this.httpClient
      .get<OperatingDataResponse[] | undefined>(`${this.apiUrl}/asset/${assetId}/inventory/opdata`)
      .pipe(
        map(result => result ?? []),
        map(result => result.map(row => ({ ...row, updatedAt: new Date(row.updatedAt) }))),
      );
  }

  public fetchInventoryAssetDetails(assetId: string): Observable<AssetInventoryDetails> {
    return this.httpClient
      .get<AssetInventoryDetailsResponse>(`${this.apiUrl}/asset/${assetId}/inventory/details`)
      .pipe(
        map(result => ({
          lastConnectedDate: result?.lastConnectedDate
            ? fromUnixTimestamp(result.lastConnectedDate)
            : undefined,
          systemState: result.systemState
            ? {
                value: result.systemState.value,
                lastUpdated: fromUnixTimestamp(result.systemState.timestamp),
              }
            : undefined,
        })),
      );
  }

  private postProcessAsset() {
    this.asset.images?.forEach(x => {
      x.ready = false;
    });
    if (this.asset.profilePicture) {
      this.asset.profilePicture.ready = false;
    }

    this.loadingProfilePicture?.unsubscribe();
    this.loadingProfilePicture = this.profilePictureService
      .getProfilePicture(this.asset)
      .pipe(
        switchMap(result => {
          if (result.picture.uuid) {
            this.treeService.updateNode({
              uuid: this.asset.uuid,
              profilePicture: result.picture.uuid,
            });

            return of(undefined);
          }

          // if we have write access, see if the miniature image should be updated
          if (!this.asset.permissions?.includes('w')) {
            return of(undefined);
          }

          // ... but only when there are asset details:
          if (!this.asset.details) {
            return of(undefined);
          }

          // ... and when there are new data to update it with:
          if (result.picture.small === undefined) {
            return of(undefined);
          }

          // ... and when the new data is in fact different:
          if (this.asset.details.productPictureUrl === result.picture.small) {
            return of(undefined);
          }

          this.logger.info('Current asset details', this.asset.details);
          this.logger.info('Updating profile picture w/ dataset:', result.picture.small);

          this.treeService.updateNode({
            uuid: this.asset.uuid,
            profilePicture: '',
            productPictureUrl: result.picture.small || '',
          });

          this.asset.details.productPictureUrl = result.picture.small;
          return this.updateAsset({ productPictureUrl: result.picture.small }, this.asset.uuid);
        }),
      )
      .subscribe();
  }
}
