import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetItem, AssetRootItem, GetAssetResponse } from '@assethub/shared/models';
import { pathFromRoutingTemplate } from '@assethub/shared/utils';
import { Subscription } from 'rxjs';
import { LanguageService, LicensingService, TreeService } from '../../services';
import { IconComponent } from '../icon/icon.component';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { TooltipComponent } from '../tooltip/tooltip.component';

@Component({
  selector: 'app-basic-menu-item-asset',
  templateUrl: '../menu/menu-item/menu-item.component.html',
  styleUrls: ['../menu/menu-item/menu-item.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, TooltipComponent],
})
export class BasicMenuItemAssetComponent
  extends MenuItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() asset?: GetAssetResponse | AssetItem;

  protected subscriptions: Subscription[] = [];
  protected reasons?: { [k: string]: string };
  private _reason = '';

  constructor(
    sanitizer: DomSanitizer,
    parentMenu: BasicMenuComponent | null,
    protected languageService: LanguageService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected treeService: TreeService,
    protected licensingService: LicensingService,
  ) {
    super(sanitizer, parentMenu);
  }

  static isViableOption(
    asset: GetAssetResponse | AssetItem | undefined,
    opt: {
      treeService: TreeService;
      licensingService: LicensingService;
    },
  ): boolean {
    return false;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    const opt = {
      treeService: this.treeService,
      licensingService: this.licensingService,
    };
    this._reason = this.asset ? this.getReason(opt) : 'disabled';
    this.update();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes.asset) {
      const opt = {
        treeService: this.treeService,
        licensingService: this.licensingService,
      };
      this._reason = this.asset ? this.getReason(opt) : 'disabled';
      this.update();
    }
  }

  ngOnDestroy(): void {
    // not sure if until-destroyed works across class inheritance
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  protected setup(icon: string, rawLabel: string) {
    this.icon = icon;
    this.subscriptions.push(
      this.languageService.translate(rawLabel).subscribe({ next: text => (this.label = text) }),
      this.languageService.translate('assetDetails.reasons').subscribe({
        next: text => {
          this.reasons = text;
          this.update();
        },
      }),
    );
  }

  private update(): void {
    this.reason = '';
    if (this._reason === '') {
      this.disabled = false;
      return;
    }
    this.disabled = true;
    if (this.reasons === undefined || this._reason === 'disabled') {
      return;
    }
    this.reason = this.reasons[this._reason] || '';
  }

  protected getReason(opt: {
    treeService: TreeService;
    licensingService: LicensingService;
  }): string {
    // override me to return a reason
    return (this.constructor as typeof BasicMenuItemAssetComponent).isViableOption(this.asset, opt)
      ? ''
      : 'disabled';
  }

  protected forgetAsset(uuid: string): void {
    this.treeService.deleteNode(uuid);
    const newSelection = this.treeService.selectedNode();
    if (!newSelection) {
      this.router.navigate(['/welcome']);
      return;
    }
    this.router.navigate(
      pathFromRoutingTemplate(this.activatedRoute.snapshot, { uuid: newSelection.uuid }),
    );
  }

  protected getRoot(): AssetRootItem {
    if (!this.asset) {
      throw new Error();
    }
    return BasicMenuItemAssetComponent.getRootOfAsset(this.asset, this.treeService);
  }

  // some helper functions to deal with the asset
  static isWritable(asset: GetAssetResponse | AssetItem | undefined): boolean {
    if (!asset) {
      return false;
    }
    if (asset instanceof AssetItem) {
      return !asset.readonly;
    }
    return asset.permissions?.includes('w') ?? true;
  }

  static isAdministrable(asset: GetAssetResponse | AssetItem | undefined): boolean {
    if (!asset) {
      return false;
    }
    if (asset instanceof AssetItem) {
      return asset.administrable;
    }
    return asset.permissions?.includes('a') ?? true;
  }

  static isManagedExternally(asset: GetAssetResponse | AssetItem | undefined): boolean {
    if (!asset) {
      return false;
    }
    if (asset instanceof AssetItem) {
      return asset.extern;
    }
    return asset.managed === true;
  }

  static isRoot(asset: GetAssetResponse | AssetItem | undefined): boolean {
    if (!asset) {
      return false;
    }
    if (asset instanceof AssetItem) {
      return asset.isRoot();
    }
    return asset.rootUuid === null;
  }

  static isShared(
    asset: GetAssetResponse | AssetItem | undefined,
    treeService: TreeService,
  ): boolean {
    if (!asset) {
      return false;
    }
    const root = BasicMenuItemAssetComponent.getRootOfAsset(asset, treeService);
    return root.owner !== undefined;
  }

  protected static getRootOfAsset(
    asset: GetAssetResponse | AssetItem,
    treeService: TreeService,
  ): AssetRootItem {
    return asset instanceof AssetItem
      ? asset.root
      : treeService.mustFindNode(asset.rootUuid ?? asset.uuid).root;
  }
}
