import { SDSColors } from '@assethub/shared/utils';

export type FirmwareState = 'CURRENT' | 'UPDATE_AVAILABLE' | 'UNKNOWN';

export const AVAILABLE_FIRMWARE_STATES: FirmwareState[] = [
  'CURRENT',
  'UPDATE_AVAILABLE',
  'UNKNOWN',
];

export const FIRMWARE_STATE_ICON: Record<
  FirmwareState,
  { shape: string; color: string; label: string; labelColor?: string; size?: string }
> = {
  CURRENT: {
    shape: 'check',
    color: SDSColors.secondary,
    label: 'asset.firmware.state.CURRENT.displayLabel',
    labelColor: SDSColors.secondaryDark,
  },
  UPDATE_AVAILABLE: {
    shape: 'custom_firmware_update_available',
    color: SDSColors.primary,
    size: '6px',
    label: 'asset.firmware.state.UPDATE_AVAILABLE.displayLabel',
  },
  UNKNOWN: {
    shape: 'help',
    color: SDSColors.secondary,
    label: 'asset.firmware.state.UNKNOWN.displayLabel',
    labelColor: SDSColors.secondaryDark,
  },
};

export const FIRMWARE_STATE_FILTER_ICON: Record<
  FirmwareState,
  { shape: string; color: string; label: string }
> = {
  CURRENT: {
    shape: 'check',
    color: SDSColors.secondary,
    label: 'asset.firmware.state.CURRENT.displayLabel',
  },
  UPDATE_AVAILABLE: {
    shape: 'warning',
    color: SDSColors.warning,
    label: 'asset.firmware.state.UPDATE_AVAILABLE.displayLabel',
  },
  UNKNOWN: {
    shape: 'help',
    color: SDSColors.secondary,
    label: 'asset.firmware.state.UNKNOWN.displayLabel',
  },
};
