import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ENVIRONMENT } from '../models';
import { SearchArtifactResponse } from '../models/software-artifact';

@Injectable({
  providedIn: 'root',
})
export class ArtifactRepoService {
  private artifactRepositoryUrl = inject(ENVIRONMENT).artifactRepositoryUrl;

  constructor(private httpClient: HttpClient) {}

  findByPartNumber(itemNumber: string): Observable<SearchArtifactResponse> {
    return this.httpClient.get<SearchArtifactResponse>(
      this.artifactRepositoryUrl + '/artifact/item-number/' + itemNumber,
    );
  }

  downloadArtifact(endpoint: string): Observable<ArrayBuffer> {
    return this.httpClient
      .get(this.artifactRepositoryUrl + endpoint, {
        responseType: 'text',
      })
      .pipe(
        switchMap(presignedDownloadUrl => {
          const options = { responseType: 'arraybuffer' as const, withCredentials: false };
          return this.httpClient.get(presignedDownloadUrl, options);
        }),
      );
  }
}
