import { Component, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetItem, GetAssetResponse } from '@assethub/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';
import { LanguageService, LicensingService, TreeService } from '../../services';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { IconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';
import { AssetPermissionsComponent } from '../asset-permissions/asset-permissions.component';

@Component({
  selector: 'app-menu-item-asset-permissions',
  templateUrl: '../menu/menu-item/menu-item.component.html',
  styleUrls: ['../menu/menu-item/menu-item.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, TooltipComponent],
})
export class MenuItemAssetPermissionsComponent extends BasicMenuItemAssetComponent {
  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('person_add_alt_1', 'assetDetails.menu.permissions');
  }

  static override isViableOption(asset?: GetAssetResponse | AssetItem): boolean {
    return true;
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    const permissions =
      (this.asset instanceof AssetItem ? this.asset.permission : this.asset.permissions) || 'rwa';
    AssetPermissionsComponent.open(this.asset.uuid, permissions);
  }
}
