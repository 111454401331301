<div class="callout {{ severity() }}">
  <div class="stripe"></div>
  <app-icon [shape]="shape()"></app-icon>
  <div class="callout-content">
    <ng-content></ng-content>
  </div>
  <!-- Close button -->
  @if (closable()) {
    <button
      id="close-button"
      pButton
      type="button"
      class="ui-button-bare-neutral icon-button-small"
      icon="pi pi-times"
      (click)="close()"
    ></button>
  }
</div>
